import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import TreeInfoDocument from '../components/TreeInfoDocument'
import { useRecoilState } from 'recoil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { loadingState, onScanGetResult, treeQrState } from '../utils/RecoilStates';

const TreeInfoViewer = ({ history }) => {
    let { slug } = useParams();
    const [treeQrData, setTreeQrData] = useRecoilState(treeQrState)
    const [loading, setLoading] = useRecoilState(loadingState)

    useEffect(() => {
        onScanGetResult(slug, setLoading, setTreeQrData, history)
    }, [setLoading, setTreeQrData])

    return (
        loading ?
            <div style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress />
            </div> :
            <TreeInfoDocument data={treeQrData} />
    )
}

export default TreeInfoViewer
