import React from 'react'
import { Container, Paper, Button, makeStyles } from '@material-ui/core';
import { FiDownload, FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { RiZoomOutLine, RiZoomInLine } from 'react-icons/ri';
import { BiReset } from 'react-icons/bi';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const TreeInfoDocument = (props) => {
    const data = props?.data

    const useStyles = makeStyles({
        root: {
            minHeight: '92vh',
            padding: 15,
            marginTop: 10,
            marginLeft: -5,
            marginRight: -5,
            justifyContent: 'center',
            display: 'flex',
        },
        rootImage: {
            backgroundImage: "url(/Rabindra-Sarobar.png)",
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0.3
        }
    });

    const classes = useStyles();

    const downloadPdfDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                let imgWidth = 208;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`${data?.name}.pdf`);
            })
    }

    return (
        <Container maxWidth="lg" id="divToPrint">
            <div className={classes.root}>
                {/* <div className={classes.rootImage} /> */}
                <TransformWrapper>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                            <TransformComponent contentStyle={{ display: 'block', minHeight: '100vh' }}>
                                <h2 style={{ textAlign: 'center', fontStyle: "italic", marginBottom: 0 }}>{data?.name}</h2>
                                <h4 style={{ textAlign: 'center', marginBottom: 0, marginTop: 0 }}><span style={{ color: '#001dc4', fontWeight: 'bold' }}>Author:</span> {data?.author}</h4>
                                <h4 style={{ textAlign: 'center', marginTop: 0 }}><span style={{ color: '#001dc4', fontWeight: 'bold' }}>Family from: </span> {data?.family}</h4>
                                <h4><span style={{ color: '#001dc4', fontWeight: 'bold' }}>English Name:</span> {data?.english_name}</h4>
                                {data?.bengali_name && <h4><span style={{ color: '#001dc4', fontWeight: 'bold' }}>Bengali Name:</span> {data?.in_bengali}({data?.bengali_name})</h4>}
                                <h4><span style={{ color: '#001dc4', fontWeight: 'bold' }}>No. of individuals:</span> {data?.no_of_individuals}</h4>
                                <h4 style={{ textAlign: 'center', marginBottom: 0, color: '#001dc4', fontWeight: 'bold' }}><u>Uses/Notes</u></h4>
                                <p style={{textAlign: 'justify'}}>{data?.descriptions}</p>
                            </TransformComponent>
                            <div className="tools">
                                <Button onClick={() => zoomIn()}><RiZoomInLine size={20} /></Button>
                                <Button onClick={() => zoomOut()}><RiZoomOutLine size={20} /></Button>
                                <Button onClick={() => resetTransform()}><BiReset size={20} /></Button>
                                <Button onClick={downloadPdfDocument}><FiDownload size={20} /></Button>
                            </div>
                        </>
                    )}
                </TransformWrapper>
            </div>
        </Container>
    )
}

export default TreeInfoDocument
