import { atom } from 'recoil';
import { localStorageEffect } from './PersistenceStore';

export const toggleMenuState = atom({
    key: 'TOGGLE_MENU',
    default: false,
    effects_UNSTABLE: [
        localStorageEffect("toggleMenu"),
    ],
});

export const menuState = atom({
    key: 'MENU_LIST',
    default: {}
});

export const menuOpenState = atom({
    key: 'MENU_OPEN',
    default: false,
    effects_UNSTABLE: [
        localStorageEffect("openMenu"),
    ],
});