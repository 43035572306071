import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRecoilValue } from 'recoil';
import { pageLoadingState } from '../utils/RecoilStates'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    padding: 0,
    margin: 0
  },
  MuiPaperRoot: {
    borderRadius: "50%"
  }
}));


export default function AppLoader() {
  const classes = useStyles();
  const openLoader = useRecoilValue(pageLoadingState);

  return (
    <Dialog
      open={openLoader}
      className={classes.modalContainer}>
      <div className={classes.modalBody}>
        <CircularProgress size={30} thickness={5} />
      </div>
    </Dialog>
  );
}