import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <p>
                <i><a rel="noreferrer" href="#" target="_blank">Poewred By - Sysm Technology</a></i>
            </p>
        </div>
    )
}

export default Footer
