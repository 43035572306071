import React from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import Layout from './components/Layout/Admin';
import Login from './pages/Auth/Login';
import TreeInfoViewer from './pages/TreeInfoViewer';
import Page404 from './pages/Common/Page404';
import PrivateRoute from './utils/resources/Auth/PrivateRoute';

const App = () => {

  const history = useHistory()

  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route exact path="/:slug" component={TreeInfoViewer} />
          <Route path="/admin/login" component={Login} />
          <PrivateRoute path="/admin/app" render={props => <Layout {...props} />} />
          <Route path="*" component={Page404} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App