import React from 'react'
const Home = React.lazy(() => import('../../pages/Home'))
const TreeList = React.lazy(() => import('../../pages/TreeList'))
const CreateTree = React.lazy(() => import('../../pages/CreateTree'))
const VisitedUser = React.lazy(() => import('../../pages/VisitedUser'))

const routes = () => {
    return [{
        admin: [
            // { Navigation: '/home', exact: true, component: Home },
            { Navigation: '/admin/app/tree-list', exact: true, component: TreeList },
            { Navigation: '/admin/app/visited-user', exact: true, component: VisitedUser },
            { Navigation: '/admin/app/create-tree', exact: true, component: CreateTree },
        ]
    },
    {
        page: [
            { Navigation: '/tree/:slug', exact: true, component: Home },
        ]
    }]
}

export default routes;