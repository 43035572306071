import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import { userDataState } from '../../RecoilStates/AuthState';


export default function PrivateRoute({ component: content, ...rest }) {
	const userData = useRecoilValue(userDataState);
	return (
		<Route
			render={({ location }) => {
				if (userData?.token) {
					return <Route component={content} {...rest} />;
				}
				return <Redirect
					to={{
						pathname: "/admin/login",
						state: { from: location }
					}}
				/>;
			}}
		/>
	);
}