import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar, CssBaseline, Typography, Button, IconButton
} from '@material-ui/core';
import { RiMenuUnfoldLine, RiLogoutCircleLine } from "react-icons/ri";
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { toggleMenuState, userDataState, logoutState } from '../../../utils/RecoilStates/';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        minHeight: 48,
        maxHeight: 48,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    headerRightConternt: {
        top: 8,
        color: "#FFF",
        right: 30,
        position: "absolute",
        lineHeight: 1,
        borderColor: "#FFF",
        fontSize: 12,
        padding: "4px 12px"
    }
}));


function Header() {
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(toggleMenuState);
    const userData = useRecoilValue(userDataState);
    const resetUserData = useResetRecoilState(userDataState);
    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleLogOut = () => {
        logoutState(resetUserData, userData?.token)
    }

    return (
        <div>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isDrawerOpen,
                })}
            >
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: isDrawerOpen,
                        })}
                    >
                        <RiMenuUnfoldLine />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Rabindra Sarobar Tree Tnformation
                    </Typography>
                    <div>
                        <Button
                            className={classes.headerRightConternt}
                            variant="outlined"
                            startIcon={<RiLogoutCircleLine />}
                            onClick={() => handleLogOut()}>
                            Logout
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Header