import { atom } from 'recoil';

export const loadingState = atom({
    key: 'LOADING_STATUS',
    default: false
});

export const pageLoadingState = atom({
    key: 'PAGE_LOADING_STATUS',
    default: false
});