import React from 'react';
import { Container } from '@material-ui/core';
import loginBanner from "../../utils/resources/Images/login-banner.png"
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSetRecoilState } from "recoil";
import { Formik } from 'formik'
import * as Yup from 'yup';
import { loginAction, userDataState } from '../../utils/RecoilStates';

export default function Login({ history }) {

    const setUserData = useSetRecoilState(userDataState);

    const loginSchema = Yup.object().shape({
        UserName: Yup.string()
            .min(2, 'User name is too short!')
            .max(50, 'User name is too Long!')
            .required('User name is required'),
        Password: Yup.string()
            .min(2, 'Password is too Too short!')
            .max(50, 'Password is too long!')
            .required('Password is required')
    });

    return (
        <React.Fragment>
            <Container maxWidth="md">
                <div className="login-container">
                    <div className="login-form-container">
                        <Grid
                            container
                            style={{ height: 350 }}
                            spacing={0}
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={6}>
                                <img className="login-banner" src={loginBanner} alt="Logon Banner" />
                            </Grid>
                            <Grid item xs={6}>
                                <Formik
                                    initialValues={{
                                        UserName: '',
                                        Password: ''
                                    }}
                                    validationSchema={loginSchema}
                                    onSubmit={(values, actions) => { loginAction(values, setUserData, actions, history) }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form noValidate autoComplete="off">
                                            <FormControl className="login-form">
                                                <TextField
                                                    className="form-input"
                                                    required
                                                    error={errors.UserName && touched.UserName ? true : false}
                                                    helperText={errors.UserName}
                                                    autoFocus
                                                    id="filled-required"
                                                    label="User Id"
                                                    variant="outlined"
                                                    style={{ marginBottom: 15 }}
                                                    margin="dense"
                                                    value={values.UserName}
                                                    onChange={handleChange('UserName')} />
                                                <TextField
                                                    className="form-input"
                                                    required
                                                    error={errors.Password && touched.Password ? true : false}
                                                    helperText={errors.Password}
                                                    id="filled-password-input"
                                                    label="Password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    variant="outlined"
                                                    style={{ marginBottom: 15 }}
                                                    margin="dense"
                                                    value={values.Password}
                                                    onChange={handleChange('Password')} />
                                                <Button
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    className="login-button">
                                                    {isSubmitting ?
                                                        <CircularProgress size={24} color="primary" className="button-progress" /> :
                                                        "Login"}
                                                </Button>
                                            </FormControl>
                                        </form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
        </React.Fragment >
    );
}