import { atom } from 'recoil';
import axios from 'axios'
import { localStorageEffect } from './PersistenceStore';
import { BASE_URL } from '../../utils/resources/api';

export const userDataState = atom({
    key: 'USER_LOGIN',
    default: {},
    effects_UNSTABLE: [
        localStorageEffect("userLogin"),
    ],
});

export const loginAction = async (values, setUserData, actions, history) => {
    actions.setSubmitting(true)

    axios({
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        url: `${BASE_URL}login`,
        data: {
            email: values.UserName,
            password: values.Password
        }
    })
        .then(function (response) {
            actions.setSubmitting(false)
            if (response.status === 200) {
                if (response.data.status === true) {
                    setUserData(response.data)
                    history.push('/admin/app/tree-list')
                }
                actions.setErrors({ UserName: "Invalid Credentials" })
            }
        }).catch(function (err) {
            actions.setErrors({ UserName: "Invalid Credentials or check network"})
            actions.setSubmitting(false)
        })
}

export const logoutState = async (setUserData) => {
    setUserData()
    localStorage.clear();
}