import React from 'react';
import { Switch } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { toggleMenuState, menuState } from '../../utils/RecoilStates';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../../utils/resources/routes'
import PrivateRoute from '../../utils/resources/Auth/PrivateRoute';
import { Route } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
   },
   toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
   },
   content: {
      flexGrow: 1,
      padding: theme.spacing(1)
   },
}));

const AppContent = () => {
   const classes = useStyles();
   const isDrawerOpen = useRecoilValue(toggleMenuState);

   const routeList = routes()


   return (
      <div className={classes.root}>
         <main className={classes.content} style={isDrawerOpen ? { marginLeft: 240 } : { marginLeft: 70 }}>
            <Switch>
               {routeList[0].admin.map((route, index) => {
                  return route.component && (
                     <PrivateRoute
                        key={index}
                        path={route.Navigation}
                        exact={route.exact && route.exact}
                        name={route.MenuName}
                        render={props => (
                           <route.component {...props} />
                        )} />
                  )
               })}
               {routeList[1].page.map((route, index) => {
                  return route.component && (
                  <Route key={index} component={route.component} path={route.Navigation} />
                  )
               })}
            </Switch>
         </main>
      </div>
   )
}

export default React.memo(AppContent)