import React from 'react'

export default function Page404() {

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
            height: "calc(100vh - 30px)",
        }}>
            <div style={{
                textAlign: 'center'
            }}>
                <h3>Welcome to</h3>
                <h1 style={{ fontSize: 28 }}>RABINDRA SAROBAR</h1>
                <h2>Something's Missing...</h2>
                <p>Please scan QR Code with phone camera and get back to tree information screen.</p>
            </div>
        </div>
    )
}
