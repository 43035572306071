import React from 'react'
import Sidebar from './Sidebar'
import AppContent from '../AppContent'
import Footer from './Footer'
import Header from './Header'
import AppLoader from '../../AppLoader'

const AdminLayout = () => {
    return (
        <>
            <Header />
            <Sidebar />
            <React.Suspense fallback={<AppLoader />}>
                <AppLoader />
                <AppContent />
            </React.Suspense>
            <Footer />
        </>
    )
}

export default AdminLayout
