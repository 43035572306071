import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, Collapse, List, Divider, IconButton, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { RiMenuUnfoldLine, RiMenuFoldLine, RiArrowDownSLine, RiArrowRightSLine, RiCheckboxBlankFill, RiCheckboxBlankCircleLine, RiCheckboxFill } from "react-icons/ri";
import { useRecoilState } from 'recoil';
import { toggleMenuState } from '../../../utils/RecoilStates';
import routes from '../../../utils/resources/routes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    minHeight: 48,
    maxHeight: 48,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: 48,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
}));

const Sidebar = () => {

  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(toggleMenuState);

  const menuList = [
    { MenuName: "Tree List", Navigation: '/admin/app/tree-list' },
    // { MenuName: "Visited User List", Navigation: '/visited-user' },
  ]

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={() => setIsDrawerOpen(false)}>
          {theme.direction === 'rtl' ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuList?.map((menu, index) =>
          <React.Fragment key={index}>
            <ListItem
              button
              selected={history.location.pathname === menu.Navigation ? true : false}
              onClick={() => history.push(menu.Navigation)}>
              <ListItemIcon>{index % 2 === 0 ? <RiCheckboxBlankFill /> : <RiCheckboxBlankFill />}</ListItemIcon>
              <ListItemText primary={menu.MenuName} />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </Drawer>
  )
}

export default Sidebar
