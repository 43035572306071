import { atom } from 'recoil';
import axios from 'axios'
import { BASE_URL, ROOT_URL } from '../resources/api';

export const allTreeState = atom({
    key: 'ALL_TREE',
    default: []
});

export const treeQrState = atom({
    key: 'TREE_QR_STATE',
    default: []
});

export const getAllTree = async (authToken, setTreeData, setLoading) => {
    setLoading(true)
    axios({
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        url: `${BASE_URL}tree-info`,
    })
        .then(function (response) {
            setLoading(false)
            if (response.status === 200) {
                if (response.data.status === true) {
                    setTreeData(response.data.data)
                }
            }
        }).catch(function (err) {
            setLoading(false)
            console.log(err);
        })
}

export const onScanGetResult = async (slug, setLoading, setTreeQrData, history) => {
    setLoading(true)
    axios({
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        url: ROOT_URL + slug,
    })
        .then(function (response) {
            setLoading(false)
            if (response.status === 200) {
                if (response.data.status === true) {
                    setTreeQrData(response.data.data)
                } else {
                    history.push('/app/no-result')
                }
            }else{
                history.push('/app/no-result')
            }
        }).catch(function (err) {
            history.push('/app/no-result')
            setLoading(false)
            console.log(err);
        })
}